<template>
  <div>
    <SubHeader :type="$t('navbar.users')" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="bg-gray-200 md:rounded-lg my-5 py-3 md:py-0">
        <div class="form-control px-4 py-2 md:py-5">
          <label class="label pt-0">
            <span class="label-text">Keyword</span>
          </label>
          <input type="text" :placeholder="$t('users.search')" class="dl-input max-w-md bg-white" v-model="filterKeyword" v-debounce:700ms="search" />
        </div>
      </div>
      <div class="w-full mb-20">
        <div class="mx-auto ">
          <div class="flex flex-col">
            <div v-if="$store.state.AdminUsers.loading" class="mt-12 w-full flex items-center justify-center">
              <Loader :onlyLoader="true" />
            </div>
            <div v-if="!$store.state.AdminUsers.loading">
              <PaginationSelect :current_page="$store.state.AdminUsers.meta.current_page" :from="$store.state.AdminUsers.meta.from" :last_page="$store.state.AdminUsers.meta.last_page" :path="$store.state.AdminUsers.meta.path" :per_page="$store.state.AdminUsers.meta.per_page" :to="$store.state.AdminUsers.meta.to" :total="$store.state.AdminUsers.meta.total" @change-page="paginationChange" />
              <div class="py-2 align-middle inline-block min-w-full" v-if="$store.state.AdminUsers.data.length">
                <div class="border border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                          <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'id' ? '-id' : 'id')">
                            <span>{{ $t("users.id") }}</span>
                            <TableSortDesc v-if="sort == '-id'" />
                            <TableSortAsc v-if="sort == 'id'" />
                          </div>
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                          <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'is_active' ? '-is_active' : 'is_active')">
                            <span>{{ $t("users.state") }}</span>
                            <TableSortDesc v-if="sort == '-is_active'" />
                            <TableSortAsc v-if="sort == 'is_active'" />
                          </div>
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                          <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'email' ? '-email' : 'email')">
                            <span>{{ $t("users.email") }}</span>
                            <TableSortDesc v-if="sort == '-email'" />
                            <TableSortAsc v-if="sort == 'email'" />
                          </div>
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                          <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'name' ? '-name' : 'name')">
                            <span>{{ $t("users.name") }}</span>
                            <TableSortDesc v-if="sort == '-name'" />
                            <TableSortAsc v-if="sort == 'name'" />
                          </div>
                        </th>
                        <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase tracking-wider text-left">
                          <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'created_at' ? '-created_at' : 'created_at')">
                            <span>{{ $t("users.created") }}</span>
                            <TableSortDesc v-if="sort == '-created_at'" />
                            <TableSortAsc v-if="sort == 'created_at'" />
                          </div>
                        </th>
                        <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase tracking-wider text-left">
                          <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'updated_at' ? '-updated_at' : 'updated_at')">
                            <span>{{ $t("users.updated") }}</span>
                            <TableSortDesc v-if="sort == '-updated_at'" />
                            <TableSortAsc v-if="sort == 'updated_at'" />
                          </div>
                        </th>
                        <th scope="col" class="px-2 py-3 rounded-t-lg"></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(user, userIndex) in $store.state.AdminUsers.data" :key="userIndex" class="hover:bg-gray-100 transition" @dblclick="$router.push({ name: 'UserShow', params: { id: user.id } })">
                        <td class="px-6 py-3 whitespace-nowrap" :class="[userIndex == $store.state.AdminUsers.data.length - 1 ? 'rounded-b-lg' : '']">
                          <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900"> #{{ user.id }} </span>
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap">
                          <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="user.is_active">{{ $t("users.active") }}</span>
                          <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 uppercase" v-if="!user.is_active">{{ $t("users.inactive") }}</span>
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap flex flex-row gap-4 items-center">
                          <img :src="user.avatar" class="rounded-full w-10" />
                          <div class="text-sm text-gray-700 font-semibold">{{ user.email }}</div>
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap">
                          <div class="text-sm text-gray-700 font-semibold">{{ user.name }}</div>
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-left text-sm text-gray-500">
                          {{ $moment(user.created_at).fromNow(true) }}<br /><small class="text-xs font-semibold text-gray-600">{{ $moment(user.created_at).format("YYYY-MM-DD HH:mm") }}</small>
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-left text-sm text-gray-500">
                          {{ $moment(user.updated_at).fromNow(true) }}<br /><small class="text-xs font-semibold text-gray-600">{{ $moment(user.updated_at).format("YYYY-MM-DD HH:mm") }}</small>
                        </td>
                        <td class="pr-6 py-3 text-right" :class="[userIndex == $store.state.AdminUsers.data.length - 1 ? 'rounded-b-lg' : '']">
                          <Dropdown>
                            <template v-slot:buttons>
                              <router-link :to="{ name: 'Admin users show', params: { id: user.id } }" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800" role="menuitem"> <Icon icon="tabler:eye" class="w-6 h-6 text-gray-500" /> {{ $t("users.see") }} </router-link>
                              <router-link :to="{ name: 'Admin users edit', params: { id: user.id } }" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800" role="menuitem"> <Icon icon="tabler:edit" class="w-6 h-6 text-gray-500" /> {{ $t("users.edit") }} </router-link>
                              <router-link :to="{ name: 'Admin users password', params: { id: user.id } }" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800" role="menuitem"> <Icon icon="tabler:lock" class="w-6 h-6 text-gray-500" /> {{ $t("users.password") }} </router-link>
                              <a href="javascript:void(0);" @click="remove(user.id)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-800 group" role="menuitem"> <Icon icon="tabler:trash" class="w-6 h-6 text-gray-500" /> {{ $t("users.delete") }} </a>
                              <a :href="`${env.VUE_APP_URL_API}/api/2.0/auth/login/user/${user.id}?redirect=${env.VUE_APP_URL}/login`" v-if="user.can_be_personified" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-blue-100 group" role="menuitem">
                                {{ $t("users.loginWithUser") }}
                              </a>
                            </template>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <div class="flex flex-col items-center mt-20">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-32 text-gray-300">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                  <span class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.notfound") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader"
import Dropdown from "@/components/Dropdowns/Dropdown"
import PaginationSelect from "@/components/PaginationSelect"
import TableSortDesc from "@/components/Table/TableSortDesc"
import TableSortAsc from "@/components/Table/TableSortAsc"
import Loader from "@/components/UI/Loader.vue"

import { Icon } from "@iconify/vue"

export default {
  name: "AdminUsers",
  components: {
    SubHeader,
    Dropdown,
    PaginationSelect,
    TableSortDesc,
    TableSortAsc,
    Loader,
    Icon
  },

  computed: {},

  data: function () {
    return {
      filterKeyword: "",
      sort: "-id",
      limit: 50,
      default: {
        sort: "-id",
        limit: 50
      },
      env: process.env
    }
  },

  async mounted() {
    this.search()
  },

  methods: {
    sortAndRefresh(sort) {
      this.sort = sort
      this.search()
    },

    search: function (config = {}) {
      let filter = {}

      if (this.filterKeyword) filter["filter[id|email|name][like]"] = `%${this.filterKeyword}%`

      filter.page = config.page || 1
      filter.sort = this.sort || this.default.sort
      filter.limit = this.limit || this.default.limit

      this.$store.dispatch("AdminUsers/list", filter)
    },

    paginationChange: function (page) {
      this.search({ page })
    },

    remove: function (id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          let status = this.$store.dispatch("AdminUsers/remove", id)
          if (status) {
            this.search()
            this.$toast.success("Se elimino con éxito")
          } else {
            this.$toast.error("Ocurrió un error, por favor vuelva intentar")
          }
        }
      })
    }
  }
}
</script>
